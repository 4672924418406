<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="sidebar-right"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Tambah Surat Komunikasi
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <b-form
          class="p-2"
          @submit.prevent="SaveIntComm()"
        >
          <b-form-input
          v-model="employee_code"
          name="employee_code"
          ref="employee_code"
          id="employee_code"
          hidden
          />
          <b-form-group
            label="Keterangan Komunikasi"
            label-for="komunikasi-tipe"
          >
            <v-select
              id="komunikasi-tipe"
              v-model="IntComm"
              placeholder="Keterangan Komunikasi"
              label="statusName"
              :options="IntCommReff"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Tanggal"
            label-for="date"
          >
            <flat-pickr
              id="date"
              v-model="date"
              class="form-control"
              placeholder="Pilih Tanggal"
            />
          </b-form-group>
          <b-form-group
            label="Catatan"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              rows="3"
              placeholder="Ketik Catatan"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Lampiran"
            label-for="attachment_file"
          >
            <b-form-file
              id="attachment_file"
              v-model="attachment_file"
              accept="image/jpeg, application/pdf"
              placeholder="Klik disini untuk memilih file"
              @change="onChange"
            />

            <b-card-text class="my-1">
              Ukuran File : <strong>{{ attachment_file ? (attachment_file.size / 1048576).toFixed(2) + ' MB': ''  }} / 3 MB</strong>
            </b-card-text>
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              block
            >
              <b-spinner
                v-show="isSpinner"
                class="ml-auto"
                :hidden="!isSpinner"
                small
                type="grow"
              />
              <div :hidden="isSpinner">
                <span class="align-middle">Simpan</span>
              </div>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetForm()"
            >
              Batal
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
    <b-modal
      ref="v-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <!-- <b-card-text v-if="isFormRequired">
        Tanggal Mulai, Tanggal Akhir & Jenis Voucher Harus di isi !!!
      </b-card-text>
      <b-card-text v-if="isValid">
        {{ message }}
      </b-card-text> -->
      <b-card-text>
        {{ message }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BButton,
  BSidebar,
  BSpinner,
  VBToggle,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCardText,
    BButton,
    BSidebar,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,
    BFormFile,
    ToastificationContent,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      // isFormRequired: false,
      // isValid: false,
      isSpinner: false,
      message: null,
      IntComm: [],
      IntCommReff: [],
      date: null,
      description: null,
      attachment_file: null,
      employee_code: null,
      employeeData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.getIntCommCode()
    this.getEmployeeCode()
  },
  methods: {
    getIntCommCode(){
      axios.get('/intcom/komTypeReff').then(response => {
        console.log(response.data.data)
        this.IntCommReff = response.data.data
      })
    },
    getEmployeeCode(){
      const empData = JSON.parse(localStorage.getItem('userData'))
      this.employee_code = empData.employee_code
      //console.log(this.employee_code);
    },
    resetForm() {
      this.$refs['sidebar-right'].hide()
      this.periode_start = null
      this.periode_end = null
      this.employee_notes = null
      this.attachment_file = null
    },
    onChange(event){
      this.attachment_file = event.target.files[0]
    },
    SaveIntComm() {
      const fdata = new FormData();
      fdata.append('employee_code', this.employee_code)
      fdata.append('com_type_code', this.IntComm.statusKode)
      fdata.append('date', this.date)
      fdata.append('description', this.description)
      fdata.append('attachment_file', this.attachment_file)

      // let config = {
      //     header : {
      //     'Content-Type' : 'multipart/form-data'
      //   }
      // } 

      console.log(fdata);
      //this.isSpinner = true
      
      axios.post('/intcom/create', fdata)
      // .then(res => { console.log(res);})
        .then(response => {
          if (response.data.data.length === 0) {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          } else {
            console.log(response)
            this.$refs['v-modal'].show()
            this.isValid = true
            this.message = response.data.message
            // console.log(this.message);
          }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            console.log(error.response)
            this.$refs['v-modal'].show()
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false
          this.resetForm()
          this.$refs['sidebar-right'].hide()
          this.$parent.fetchDataList()
        })
    },
  },
}
</script>
 <style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
