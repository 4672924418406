<template>
  <div>
    <!-- float button add -->
    <div class="btn-scroll-to-top">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-toggle.sidebar-right
        variant="primary"
        size="lg"
        class="btn-icon rounded-circle"
      >
        <feather-icon icon="PlusIcon" size="16" />
      </b-button>
    </div>
    <b-alert v-height-fade show dismissible fade class="mb-2" variant="primary">
      <div class="alert-body">
        <span>Klik tombol + untuk melakukan pengajuan surat komunikasi !</span>
      </div>
    </b-alert>
    <b-card title="Pencarian Surat Komunikasi Karyawan">
      <b-row>
        <b-col md="12">
          <b-row>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="statusReff"
                  :disabled="isSpinner"
                  placeholder="Pilih Status"
                  label="statusName"
                  :options="status"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="month_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Bulan"
                  label="monthName"
                  :options="monthPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="12">
              <b-form-group>
                <v-select
                  v-model="year_periode"
                  :disabled="isSpinner"
                  placeholder="Pilih Tahun"
                  label="year"
                  :options="yearPeriode"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="getIntCommEmployee()"
                >
                  <b-spinner
                    v-show="isSpinner"
                    class="ml-auto"
                    :hidden="!isSpinner"
                    small
                    type="grow"
                  />
                  <div :hidden="isSpinner">
                    <feather-icon icon="SearchIcon" class="mr-50" />
                    <span class="align-middle">Search</span>
                  </div>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col md="3" sm="12">
              <b-form-group>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  block
                  @click="resetFilter()"
                >
                  Reset
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <!-- is data null -->
    <div class="pricing-free-trial" v-if="isNull">
      <b-row>
        <b-col lg="10" offset-lg="3" class="mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">Data surat komunikasi tidak ditemukan!</h3>
              <h5>Klik tombol + untuk melakukan pengajuan surat komunikasi.</h5>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src=" require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ is data null -->

    <!-- card cuti -->
    <b-overlay
      :show="isOverlay"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row v-if="isTableShow">
        <b-col
          md="4"
          sm="12"
          v-for="cleave in commLeave"
          :key="cleave.code"
        >
          <b-card no-body>
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title class="mb-1">{{
                  cleave.code
                }}</b-card-title>
                <b-card-sub-title class="mb-1 font-italic">
                  Dibuat pada {{ timeAgo(cleave.created_at) }}
                </b-card-sub-title>
                <b-card-sub-title>
                  <b-badge :variant="status_kode[1][cleave.status_code]">
                    {{ status_kode[0][cleave.status_code] }}
                  </b-badge>
                  -
                  <b-badge
                    variant="light-dark"
                    v-if="!cleave.attachment_file"
                  >
                  Tidak Ada Lampiran
                  </b-badge>
                  <b-badge
                    href="#"
                    variant="light-dark"
                    user="'cleave'"
                    v-if="cleave.attachment_file"
                    @click="showAttch(cleave)"
                  >
                    <feather-icon icon="FileIcon" class="mr-25" /> Lampiran
                  </b-badge>
                </b-card-sub-title>
              </div>

              <b-dropdown
                variant="link"
                no-caret
                right
                class="chart-dropdown"
                toggle-class="p-0"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item
                  href="#"
                  @click="onClickUpdate(cleave)"
                  :disabled="cleave.status_text !== 'PROPOSED'"
                >
                  Ubah Data
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click="confirmText(cleave)"
                  :disabled="cleave.status_text !== 'PROPOSED'"
                >
                  Batal
                </b-dropdown-item>
              </b-dropdown>
            </b-card-header>

            <b-card-body>
              <b-card-text>
                Mengajukan surat komunikasi pada tanggal
                <strong>{{ stdDate(cleave.date) }}</strong> dikarenakan 
                <strong>{{ cleave.com_type_text }}</strong
                >.
              </b-card-text>

              <b-card-text class="font-italic" v-if="cleave.updated_at != null">
                Diperiksa pada {{ longDate(cleave.updated_at) }}
              </b-card-text>
              <b-card-text class="font-italic" v-else>
                Belum diperiksa
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <!-- / card cuti -->
    <b-modal
      ref="v-modal"
      id="v-modal"
      ok-variant="secondary"
      modal-class="modal-secondary"
      hide-footer
      centered
      size="lg"
      title="Lampiran"
    >
      <div>
        <b-embed frameborder="0" type="iframe" width="100%" :src="file.attachment_directory" />
      </div>
    </b-modal>
    <b-modal
      ref="u-modal"
      ok-variant="secondary"
      modal-class="modal-secondary"
      hide-footer
      centered
      size="lg"
      title="Ubah Data Surat Sakit"
    >
          <b-form class="p-2" @submit.prevent="SaveEditIntComm()">
          <b-form-group
            label="Keterangan Komunikasi"
            label-for="komunikasi-tipe"
          >
            <v-select
              id="komunikasi-tipe"
              v-model="com_type"
              placeholder="Keterangan Komunikasi"
              label="statusName"
              :options="IntCommReff"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Tanggal"
            label-for="date"
          >
            <flat-pickr
              id="date"
              v-model="date"
              class="form-control"
              placeholder="Pilih Tanggal"
            />
          </b-form-group>
          <b-form-group
            label="Catatan"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              rows="3"
              placeholder="Ketik Catatan"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group>
              <b-card-text class="my-1">
                File : <strong>{{ file_name }}</strong>
              </b-card-text>
            </b-form-group>
          <b-form-group
            label="Lampiran"
            label-for="attachment_file"
          >
            <b-form-file
              id="attachment_file"
              v-model="attachment_file"
              accept="image/jpeg, application/pdf"
              placeholder="Klik disini untuk memilih file"
              @change="onChange"
            />

            <b-card-text class="my-1">
              Ukuran File : <strong>{{ attachment_file ? (attachment_file.size / 1048576).toFixed(2) + ' MB': ''  }} / 3 MB</strong>
            </b-card-text>
          </b-form-group>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                block
              >
                <b-spinner
                  v-show="isSpinner"
                  class="ml-auto"
                  :hidden="!isSpinner"
                  small
                  type="grow"
                />
                <div :hidden="isSpinner">
                  <span class="align-middle">Simpan</span>
                </div>
              </b-button>
              <router-link
                  :to="{
                      name: 'comunication',
                    }"
                >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
              >
                Batal
              </b-button>
              </router-link>
            </div>
          </b-form>
    </b-modal>
    <b-modal
      ref="e-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <b-card-text> {{ message }} </b-card-text>
    </b-modal>

    <!-- cuti sidebar -->
    <add-com-sidebar />
    <!-- <update-cuti-sidebar :LeavesCode="LeavesCode" /> -->
  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BCardHeader,
  BEmbed,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BSpinner,
  BOverlay,
  BImg,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BForm,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import axios from "axios";
import AddComSidebar from "./AddComSidebar.vue";
import moment from "moment";
import "moment/locale/id";
import flatPickr from "vue-flatpickr-component";
//import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    BEmbed,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BSpinner,
    BOverlay,
    BImg,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    AddComSidebar,
    BForm,
    BFormTextarea,
    BFormFile,
    flatPickr,
    // VuePdfEmbed,
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isOverlay: false,
      isNull: false,
      isTableShow: false,
      isSpinner: false,
      isLoadingHideButton: true,
      isDisableByVerified: true,
      isDisableByNotSelected: true,
      status: [],
      year_periode: [],
      month_periode: [],
      statusReff: [],
      monthPeriode: [
        {
          monthCode: "01",
          monthName: "Januari",
        },
        {
          monthCode: "02",
          monthName: "Februari",
        },
        {
          monthCode: "03",
          monthName: "Maret",
        },
        {
          monthCode: "04",
          monthName: "April",
        },
        {
          monthCode: "05",
          monthName: "Mei",
        },
        {
          monthCode: "06",
          monthName: "Juni",
        },
        {
          monthCode: "07",
          monthName: "Juli",
        },
        {
          monthCode: "08",
          monthName: "Agustus",
        },
        {
          monthCode: "09",
          monthName: "September",
        },
        {
          monthCode: "10",
          monthName: "Oktober",
        },
        {
          monthCode: "11",
          monthName: "November",
        },
        {
          monthCode: "12",
          monthName: "Desember",
        },
      ],
      yearPeriode: [
        {
          yearCode: "2022",
          year: "2022",
        },
        {
          yearCode: "2023",
          year: "2023",
        },
        {
          yearCode: "2024",
          year: "2024",
        },
        {
          yearCode: "2025",
          year: "2025",
        },
        // {
        //   yearCode: "2026",
        //   year: "2026",
        // },
        // {
        //   yearCode: "2027",
        //   year: "2027",
        // },
        // {
        //   yearCode: "2028",
        //   year: "2028",
        // },
        // {
        //   yearCode: "2029",
        //   year: "2029",
        // },
        // {
        //   yearCode: "2030",
        //   year: "2030",
        // },
      ],
      employeeData: JSON.parse(localStorage.getItem("userData")),
      commLeave: [],
      status_kode: [
        {
          "KMS-00": "PROPOSED",
          "KMS-01": "APPROVED",
          "KMS-02": "VERIFIED",
          "KMS-03": "CANCELED",
          "KMS-04": "REJECTED",
        },
        {
          "KMS-00": "light-warning",
          "KMS-01": "light-info",
          "KMS-02": "success",
          "KMS-03": "light-secondary",
          "KMS-04": "light-danger",
        },
      ],
      selected: [],
      IntCommCode: [],
      file: '',
      message: '',
      com_type: null,
      IntCommReff: [],
      date: null,
      description: null,
      attachment_file: null,
      file_name: null,

    };
  },
  created() {
    this.getIntCommEmployee();
    this.getIntCommStatusReff();
  }, 
  mounted() {
    this.intervalFetchData();
  },
  methods: {
    timeAgo(date) {
      moment.locale("id");

      return moment(date).fromNow();
    },
    longDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM  YYYY h:mm a");
    },
    stdDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM YYYY");
    },
    getIntCommCode(){
      axios.get('/intcom/komTypeReff').then(response => {
        console.log(response.data.data)
        this.IntCommReff = response.data.data
      })
    },
    showAttch(cleave) {
      // v-b-modal="'v-modal'"
      console.log(cleave);
      if (cleave.attachment_ext != 'pdf') {
        window.open(cleave.attachment_directory, '_blank', "height=900,width=900");
      } else {
        this.file = cleave
        this.$refs["v-modal"].show()
      }
    },
    onChange(event){
      this.attachment_file = event.target.files[0]
    },
    closeModal() {
      this.$refs["u-modal"].hide()
    },
    resetFilter() {
      this.status = [];
      this.month_periode = [];
      this.year_periode = [];
    },
    getIntCommStatusReff() {
      axios.get("/intcom/statusReff").then((response) => {
        console.log(response.data);
        this.status = response.data.data;
      });
    },
    intervalFetchData() {
      setInterval(() => {
        this.fetchDataList();
      }, 300000);
    },
    fetchDataList() {
      axios
        .post("/intcom/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((res) => {
          this.commLeave = res.data.data;
        });
    },
    getIntCommEmployee() {
      this.isTableShow = false
      this.isOverlay = true
      axios
        .post("/intcom/list", {
          employee_code: this.employeeData.employee_code,
          month_periode: this.month_periode.monthCode,
          year_periode: this.year_periode.year,
          status: this.statusReff.statusKode,
        })
        .then((response) => {
          this.ToastDataFound();
          console.log(response.data.data);
          this.commLeave = response.data.data;
          this.isNull = false;
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 404) {
            this.isNull = true;
            this.isTableShow = false;
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          this.isOverlay = false
          this.isTableShow = true
        });
    },
    viewSelected() {
      //const sickCode = this.selected.sick_leave_code
      axios
        .post("/intcom/detil", {
          intcom_code: this.selected.code,
        })
        .then((response) => {
          if (response.data.data.attachment_file === null) {
            window.open(
              this.$router.resolve({ name: "filenotfound" }),
              "_blank",
              "height=900,width=900"
            );
          } else {
            window.open(
              response.data.data.attachment_directory,
              "_blank",
              "height=900,width=900"
            );
            console.log(response.data);
          }
        });
    },
    onClickUpdate(cleave) {
      this.ucLeave = cleave.code;
      console.log(this.ucLeave);
      this.$refs["u-modal"].show();
      if(this.usLeave !== null) {
        axios.post('intcom/detil', {
        intcom_code: this.ucLeave
        })
        .then(response => {
          console.log(response);
          this.com_type = response.data.data.com_type
          this.date = response.data.data.date
          this.description = response.data.data.description
          this.file_name = response.data.data.attachment_file
          this.detil = response.data
        })
      } else {
        console.log(error);
      }
    },
    SaveEditIntComm() {
      this.isTableShow = false
      const fdata = new FormData();
      fdata.append('_method', 'patch')
      fdata.append('intcom_code', this.ucLeave)
      fdata.append('date', this.date)
      fdata.append('com_type_code', this.com_type.statusKode)
      fdata.append('description', this.description)
      fdata.append('attachment_file', this.attachment_file)

      // let config = {
      //     header : {
      //     'Content-Type' : 'multipart/form-data'
      //   }
      // } 

      console.log(fdata);
      //this.isSpinner = true
      
      axios.post('/intcom/update', fdata)
      // .then(res => { console.log(res);})
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          } else {
            console.log(response)
            this.$refs['v-modal'].show()
            this.isValid = true
            this.message = response.data.message
            // console.log(this.message);
          }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            this.$refs['v-modal'].show()
            console.log(error.response.data.message)
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.$refs['u-modal'].hide()
          this.getIntCommEmployee()
          this.isTableShow = true
          this.isNull = false;
        })
    },
    confirmText(cleave) {
      this.$swal({
        title: "Apa anda yakin?",
        text: "Data yang sudah dibatalkan tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isTableShow = false;
          axios
            .patch("/intcom/cancle", {
              intcom_code: cleave.code,
            })
            .then(() => {
              this.$swal({
                icon: "success",
                title: "Canceled!",
                text: "Cuti anda berhasil dibatalkan.",
                timer: 1500,
              });
            })
            .catch((error) => {
              console.log(error.response.data.message);
            })
            .finally(() => {
              this.isTableShow = true;
              this.getIntCommEmployee();
            });
        }
      });
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data tidak ditemukan.",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data ditemukan.",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    // validationFormInfo() {
    //   this.$refs.infoRules.validate().then(success => {
    //     if (success) {
    //       this.getLeaveEmployee()
    //     } else {
    //       this.$refs['v-modal'].show()
    //     }
    //   })
    // },
  },
};
</script>

<style lang="scss" scoped>
.btn-scroll-to-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  z-index: 99;

  // opacity: 0;
  // // transform: translateY(100px);
  // transition: all .5s ease;

  // &.show {
  //   opacity: 1;
  //   // transform: translateY(0)
  // }
}
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
